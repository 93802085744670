import React from "react"
import { Link } from "react-router-dom"
import { Alert, Button, Space, Table, Tag, Typography } from "antd"
import formatDate from "dateformat"
import styled from "styled-components"
import { CheckCircleFilled } from "@ant-design/icons"
import { IRenewalQuoteRow } from "."
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { QuoteStateLabels } from "containers/quotes/constants"
import { QuoteState } from "platform-client/types/quote"

const AlertWrapper = styled.div`
  padding: 24px;
`

const RenewalsTable: React.FC<IRenewalsTableProps> = ({
  renewalRows,
  setActiveRenewalQuote,
  hasAssignedQuote,
  isLoadingQuotes
}): JSX.Element => {
  const renewalColumns = () => [
    {
      title: "Quote ID",
      dataIndex: "quoteReferenceID",
      key: "quoteReferenceID",
      render: (text: string, record: IRenewalQuoteRow) => (
        <Link to={`/quote/${record.quoteId}/summary`}>
          <u>{text}</u>
        </Link>
      )
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      render: (assigned: boolean) => {
        return assigned ? (
          <CheckCircleFilled style={{ color: "#1890ff", fontSize: "1.4rem" }} />
        ) : (
          ""
        )
      }
    },
    {
      title: "Premium",
      key: "premium",
      render: (text: unknown, record: IRenewalQuoteRow) => (
        <Typography.Text style={{ fontSize: "24px" }}>
          <MoneyDisplay value={record.estimatedPremium} />
        </Typography.Text>
      )
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (date: string) => formatDate(new Date(date), "yyyy-mm-dd H:M:s"),
      sorter: (a: IRenewalQuoteRow, b: IRenewalQuoteRow) =>
        new Date(a.created).getTime() - new Date(b.created).getTime()
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy"
    },
    {
      title: "Status",
      dataIndex: "quoteState",
      key: "quoteState",
      render: (quoteState: QuoteState, record: IRenewalQuoteRow) => {
        return (
          <Tag color={QuoteStateLabels[quoteState].color}>
            {QuoteStateLabels[quoteState].label.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: unknown, record: IRenewalQuoteRow) => {
        const isReferred =
          (record.quoteState === "Quoted" ||
            record.quoteState === "PendingPayment") &&
          !record.reviewApproved &&
          record.requiresReview

        const showAssign =
          !record.assigned &&
          ["Quoted", "PendingPayment", "Purchased", "Confirmed"].includes(
            record.quoteState
          ) &&
          !isReferred

        return (
          <Space size="small">
            {isReferred && (
              <Link to={`/quote/${record.quoteId}/review`}>
                Review referral
              </Link>
            )}
            {showAssign && (
              <Button
                type="link"
                onClick={() => setActiveRenewalQuote(record.quoteId)}
              >
                Assign
              </Button>
            )}
          </Space>
        )
      }
    }
  ]

  if (renewalRows.length > 0) {
    return (
      <>
        {!hasAssignedQuote && (
          <AlertWrapper>
            <Alert
              message="No renewal quotes have been assigned to the customer yet"
              type="warning"
            />
          </AlertWrapper>
        )}
        <Table
          loading={isLoadingQuotes}
          pagination={false}
          dataSource={renewalRows}
          columns={renewalColumns()}
          rowKey={(renewalRows) => renewalRows.quoteId}
        />
      </>
    )
  } else {
    return (
      <AlertWrapper>
        <Alert
          message="No renewal quotes have been generated yet"
          type="info"
        />
      </AlertWrapper>
    )
  }
}

interface IRenewalsTableProps {
  isLoadingQuotes: boolean
  renewalRows: IRenewalQuoteRow[]
  hasAssignedQuote?: boolean | undefined
  setActiveRenewalQuote: (quoteID: string) => void
}

export default RenewalsTable
