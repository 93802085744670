import React from "react"
import { Breadcrumb, Tag } from "antd"
import { LayoutCardLoader, LayoutPageHeader } from "components"
import { Link } from "react-router-dom"
import { FileProtectOutlined } from "@ant-design/icons"
import { IDocument } from "models/documents/document"
import { PolicyAdjustment } from "../policy-adjustment"
import { PolicyCancellation } from ".."
import { IPolicyAction } from "models/policies/policyAction"
import PolicyReinstatement from "../policy-reinstatement"
import { PaymentPlanType } from "platform-client/types"
import PolicyCancellationAmendment from "../policy-cancellation-amendment"

export const PolicyHeader: React.FunctionComponent<IPolicyHeader> = ({
  type,
  policyTypes,
  ...props
}) => {
  if (type) {
    const tag = policyTypes && policyTypes[type]
    return (
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/opportunities">
              <Link to="/policies">
                <FileProtectOutlined />
                &nbsp;Policies
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
          </Breadcrumb>
        }
        title={props.title}
        extra={
          props.isExternal === false && (
            <>
              {props.mtaPermission && ["Active", "Pending"].includes(type) && (
                <PolicyAdjustment
                  policyId={props.policyId}
                  coverType={props.coverType}
                  userId={props.userId}
                  productRef={props.productRef}
                  policyActions={props.policyActions}
                  productId={props.productId}
                />
              )}
              {props.cancelPolicyPermission && type !== "Cancelled" && (
                <PolicyCancellation
                  policyID={props.policyId}
                  ownerId={props.ownerId || ""}
                  policyCoverRange={props.policyCoverRange}
                  policyPurchaseDate={props.policyPurchaseDate}
                  paymentPlanType={props.paymentPlanType}
                />
              )}
              {props.cancelPolicyPermission &&
                ["Cancelled"].includes(type) &&
                props.paymentPlanType &&
                props.policyId && (
                  <>
                    <PolicyCancellationAmendment
                      policyID={props.policyId}
                      policyCoverRange={props.policyCoverRange}
                      policyPurchaseDate={props.policyPurchaseDate}
                      refetchPolicyBundle={props.refetchPolicyBundle}
                      currentCancellationReasonId={props.cancellationReason}
                      policyCoverEndDate={props.policyCoverEndDate}
                      refetchTimelineEvents={props.refetchTimelineEvents}
                      paymentPlanType={props.paymentPlanType}
                    />
                    <PolicyReinstatement
                      policyID={props.policyId}
                      paymentPlanType={props.paymentPlanType}
                      refetchPolicyBundle={props.refetchPolicyBundle}
                      policyReferenceID={props.policyReferenceID}
                      hasReinstatementEmail={props.hasReinstatementEmail}
                      recurringPaymentDates={props.recurringPaymentDates}
                    />
                  </>
                )}
            </>
          )
        }
        tags={<Tag color={tag?.color}>{tag?.tag?.toUpperCase()}</Tag>}
        footer={props.footer}
      >
        {props.children}
      </LayoutPageHeader>
    )
  } else {
    return <LayoutCardLoader />
  }
}

export interface IPolicyHeader {
  policyTypes:
    | {
        [key: string]: {
          tag: string | undefined
          color: string | undefined
        }
      }
    | undefined
  children: React.ReactNode
  title?: string
  type: string | undefined
  documents: IDocument[] | undefined
  policyId?: string
  userId?: string
  ownerId?: string
  productRef?: string
  coverType?: string
  mtaPermission?: boolean
  cancelPolicyPermission?: boolean
  policyActions?: IPolicyAction[]
  footer?: React.ReactNode
  policyCoverRange?: string[]
  policyCoverEndDate?: string
  policyPurchaseDate?: string
  paymentPlanType?: PaymentPlanType
  refetchTimelineEvents?: VoidFunction
  refetchPolicyBundle?: () => void
  policyReferenceID?: string
  hasReinstatementEmail?: boolean
  recurringPaymentDates?: string[]
  productId?: number
  isExternal?: boolean
  cancellationReason?: number
}
