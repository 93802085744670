import React, { useEffect, useState } from "react"
import { Divider } from "antd"
import { Row, Col } from "antd"
import {
  useSetPolicyRenewals,
  useGetPolicyRenewals,
  useSetPolicyRenewalsWithdraw
} from "../../../../services/hooks"
import { TPolicyRenewalsSettings } from "models/policies/policyRenewals"
import { useConfigurationContext } from "contexts/configuration/context"
import { PolicyRenewals } from "../../v2_components"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { TPolicyRenewalsBundle } from "models/policies/policyRenewals"
import PolicyRenewalQuotes from "../../v2_components/policy-renewal-quotes"
import useGetPolicyRenewalQuotes from "containers/policies/services/hooks/use-get-policy-renewal-quote"

export const PolicyRenewalsComponent: React.FunctionComponent<IPolicyRenewalsSubView> =
  (props) => {
    const configuration = useConfigurationContext()

    const [policyRenewalsData, setPolicyRenewalsData] = useState<
      TPolicyRenewalsBundle | undefined
    >()

    const {
      error,
      isLoading,
      isFirstLoad,
      renewalQuotes,
      setAssignedQuote,
      reloadQuotes
    } = useGetPolicyRenewalQuotes(props?.policyID)

    const {
      isSending: isRenewalsWorking,
      sendRequest: sendRenewalsRequest,
      isError: isRenewalsError,
      renewals: renewalsUpdated
    } = useSetPolicyRenewals(props?.policyID)

    const { policyRenewals, isPolicyRenewalsLoading } = useGetPolicyRenewals(
      props.policyID
    )

    const {
      sendRequest: setPolicyRenewalWithdraw,
      response: policyWithdrawResponse
    } = useSetPolicyRenewalsWithdraw(props.policyID, reloadQuotes)

    useEffect(() => {
      if (policyRenewals && !policyWithdrawResponse) {
        setPolicyRenewalsData(policyRenewals)
      }
      if (renewalsUpdated) {
        setPolicyRenewalsData(renewalsUpdated)
      }
      if (policyWithdrawResponse) {
        setPolicyRenewalsData(policyWithdrawResponse)
      }
    }, [policyRenewals, renewalsUpdated, policyWithdrawResponse])

    const isPolicyRenewalsWithdrawed =
      policyRenewalsData?.status === "Withdrawn"

    return (
      <>
        <Row gutter={24}>
          <Col span={24}>
            {props.policyRenewalsSettings?.supportsRenewals &&
              policyRenewalsData &&
              configuration.policy?.details && (
                <PolicyRenewals
                  renewalsSettings={props.policyRenewalsSettings.paymentPlans}
                  renewalsConfiguration={configuration.policy?.details}
                  setPolicyRenewalWithdraw={setPolicyRenewalWithdraw}
                  isPolicyRenewalsWithdrawed={isPolicyRenewalsWithdrawed}
                  policyPlanReferenceID={props.policyPlanReferenceID}
                  isError={isRenewalsError}
                  sendRequest={sendRenewalsRequest}
                  isSending={isRenewalsWorking}
                  isLoading={isPolicyRenewalsLoading}
                  values={{
                    requiresReview: policyRenewalsData.requiresReview,
                    allowAutoBind: policyRenewalsData.allowAutoBind,
                    doNotRenew: policyRenewalsData.doNotRenew
                  }}
                />
              )}
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            {props.policyRenewalsSettings?.supportsRenewals && (
              <PolicyRenewalQuotes
                policyId={props.policyID}
                isPolicyCancelled={props.isPolicyCancelled}
                isPolicyRenewalsWithdrawed={isPolicyRenewalsWithdrawed}
                noIntentionToRenew={policyRenewalsData?.doNotRenew}
                isError={error}
                isLoading={isLoading}
                isFirstLoad={isFirstLoad}
                renewalQuotes={renewalQuotes}
                setAssignedQuote={setAssignedQuote}
                reloadQuotes={reloadQuotes}
              />
            )}
          </Col>
        </Row>
      </>
    )
  }

export const PolicyRenewalsSubView = withRouter(PolicyRenewalsComponent)

export interface IPolicyRenewalsSubView extends RouteComponentProps {
  isPolicyCancelled: boolean
  policyID: string
  policyPlanReferenceID: string | undefined
  policyProductID: number | undefined
  policyRenewalsSettings: TPolicyRenewalsSettings | undefined
}
