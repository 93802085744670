import { useState, useMemo } from "react"
import { useHistory } from "react-router"
import {
  IPolicyCancelInfoRequestBody,
  IPolicyCancellationReason
} from "contexts/opus/context"
import { useSetPolicyCancellation } from "containers/policies/services/hooks/use-set-policy-cancellation"
import { PaymentPlanType } from "platform-client/types"
import Component from "./component"
import moment, { Moment } from "moment"
import { useMutation } from "react-query"
import useOpus from "contexts/opus/hooks/use-opus"

export const PolicyCancellation: React.FC<IPolicyCancellationProps> = ({
  policyID,
  ownerId,
  policyCoverRange,
  policyPurchaseDate,
  paymentPlanType
}) => {
  const history = useHistory()
  const coverRange = useMemo(() => {
    if (policyCoverRange && policyCoverRange.length) {
      return [
        moment.utc(policyCoverRange[0]),
        moment.utc(policyCoverRange[1]).endOf("day")
      ]
    }
  }, [policyCoverRange])

  const minCancellationDate = useMemo(() => {
    if (policyPurchaseDate && policyCoverRange && policyCoverRange.length) {
      const purchaseDate = moment.utc(policyPurchaseDate).startOf("day")
      const coverStart = moment.utc(policyCoverRange[0]).startOf("day")

      return moment.min([purchaseDate, coverStart])
    }
  }, [policyPurchaseDate, policyCoverRange])

  const opusClient = useOpus()

  const defaultDate = useMemo(() => {
    const today = moment.utc().startOf("day")

    if (!coverRange || (coverRange && coverRange[1].isAfter(today))) {
      return today
    }

    return coverRange[1]
  }, [coverRange])

  const [cancellationReason, setCancellationReason] = useState<
    IPolicyCancellationReason | undefined
  >(undefined)
  const [effectiveCancellationDate, setEffectiveCancellationDate] =
    useState<Moment>(defaultDate)
  const [proRataRefund, setProRataRefund] = useState<boolean>(false)

  const cancellationInfoMutation = useMutation(
    (request: IPolicyCancelInfoRequestBody) =>
      opusClient.policyClient.getPolicyCancellationInformation(
        request.policyID,
        request
      )
  )

  const fetchCancellationInfo = (
    cancellationReason: IPolicyCancellationReason | null | undefined,
    effectiveCancellationDate: Moment | null | undefined,
    proRataRefund: boolean | null | undefined
  ) => {
    if (policyID) {
      cancellationInfoMutation.mutateAsync({
        policyID: policyID,
        selectedCancellationReason: cancellationReason,
        effectiveCancellationDate: effectiveCancellationDate?.format(),
        requestedCancellationDate: null,
        suppressCancellationEmail: false,
        allowRefund: true,
        refundMethodOverride: proRataRefund === true ? "ProRata" : null
      })
    }
  }

  const { sendRequest: cancelPolicy, isLoading: isCancellationPending } =
    useSetPolicyCancellation()

  const onCancel = async () => {
    const requestBody = {
      policyID: policyID as string,
      selectedCancellationReason:
        cancellationReason as IPolicyCancellationReason,
      effectiveCancellationDate: effectiveCancellationDate.format(),
      requestedCancellationDate: null,
      suppressCancellationEmail: false,
      allowRefund: true,
      refundMethodOverride: proRataRefund === true ? "ProRata" : null
    }

    cancelPolicy(ownerId, requestBody).then(() => history.push("/policies"))
  }

  return (
    <Component
      onCancel={onCancel}
      policyRef={cancellationInfoMutation?.data?.policyRef || "-"}
      refundAmount={cancellationInfoMutation?.data?.refundAmount}
      onChangeReason={(reason) => {
        setCancellationReason(reason)
        fetchCancellationInfo(reason, effectiveCancellationDate, proRataRefund)
      }}
      cancellationReason={cancellationReason}
      cancellationReasons={
        cancellationInfoMutation?.data?.cancellationReasons || []
      }
      isButtonLoading={cancellationInfoMutation.isLoading}
      isLayoutLoading={
        cancellationInfoMutation.isLoading || isCancellationPending
      }
      fetchCancellationInfo={fetchCancellationInfo}
      effectiveCancellationDate={effectiveCancellationDate}
      setEffectiveCancellationDate={(date) => {
        setEffectiveCancellationDate(date)
        fetchCancellationInfo(cancellationReason, date, proRataRefund)
      }}
      onProRataRefundChange={(prorata) => {
        setProRataRefund(prorata)
        fetchCancellationInfo(
          cancellationReason,
          effectiveCancellationDate,
          prorata
        )
      }}
      proRataRefund={proRataRefund}
      coverRange={coverRange}
      minCancellationDate={minCancellationDate}
      paymentPlanType={paymentPlanType}
    />
  )
}

interface IPolicyCancellationProps {
  policyID?: string
  ownerId: string
  policyCoverRange?: string[]
  policyPurchaseDate?: string
  paymentPlanType?: PaymentPlanType
}
