import { useHistory } from "react-router"
import { Breadcrumb, Button, Layout, Table } from "antd"
import { useState } from "react"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { DatabaseOutlined, PlusOutlined } from "@ant-design/icons"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import useOpus from "contexts/opus/hooks/use-opus"
import { IRateFile } from "models/ratings/IRateFile"
import { RatingsBoundary } from "containers/ratings/boundary"
import Column from "antd/lib/table/Column"
import formatDate from "dateformat"

export const RatingsList: React.FC = () => {
  const opus = useOpus()
  const history = useHistory()

  const [rateFileData, setRateFileData] = useState<IRateFile[]>([])

  const { isFetching } = useQuery<IRateFile[] | undefined, AxiosError>(
    ["rateFileList"],
    async () => opus.ratingsClient.list(),
    {
      onSuccess: (result) => {
        result && setRateFileData(result)
      }
    }
  )

  return (
    <RatingsBoundary>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/ratings">
                <DatabaseOutlined />
                &nbsp;Ratings
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="Ratings"
          extra={
            <Button
              type="primary"
              onClick={() => history.replace("/ratings/upload")}
              icon={<PlusOutlined />}
            >
              Upload Rate File
            </Button>
          }
        />
        <LayoutPageContent>
          <Table
            loading={isFetching}
            dataSource={rateFileData}
            rowKey="id"
            pagination={false}
            style={{ marginTop: "10px" }}
          >
            <Column
              title="Product"
              render={(item: IRateFile) => item.productName}
            />
            <Column
              title="Date Added"
              dataIndex="dateCreated"
              render={(value: string) =>
                formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
              }
            />
            <Column
              title="Uploaded By"
              render={(item: IRateFile) => item.createdByName}
            />
            <Column
              title="Filename"
              render={(item: IRateFile) => item.filename}
            />
            <Column
              title="Rates Version"
              render={(item: IRateFile) => item.version}
            />
            <Column
              title="Changelog"
              render={(item: IRateFile) => item.changelog}
            />
            <Column title="Status" render={(item: IRateFile) => item.status} />
            <Column
              title="Go Live Date"
              render={(item: IRateFile) =>
                item.status === "TestPassed" &&
                formatDate(
                  new Date(item.goLiveDateTime),
                  "dd/mm/yyyy hh:MM:ss TT"
                )
              }
            />
            <Column
              title="Test Results"
              render={(item: IRateFile) =>
                item.testResultsUrl && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.testResultsUrl ?? ""}
                  >
                    Download
                  </a>
                )
              }
            />
          </Table>
        </LayoutPageContent>
      </Layout>
    </RatingsBoundary>
  )
}
